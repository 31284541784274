div.single-message {
  &__container {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    gap: 0.579rem;

    &.is-me {
      margin-left: auto;
      width: fit-content;
      margin-right: 1.25rem;
    }

    &.is-not-me {
      margin-right: auto;
      width: fit-content;
    }
  }

  &__text {
    padding: 0.625rem 1.25rem;
    width: fit-content;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    position: relative;

    display: flex;
    gap: 1.5rem;

    &.is-me {
      border-radius: 2.0025rem;
      background: #162739;
      align-self: flex-end;

      * {
        color: #faf8f8;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }

    &.is-not-me {
      border-radius: 2.0025rem;
      background: transparent;
      align-self: flex-start;
      * {
        color: #cfd1d3;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
  }

  &__logo {
    width: 2rem; /* Width defined */
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 0.467px solid rgba(255, 255, 255, 0.8);
    border-radius: 100%; /* This makes it circular */
    height: 2rem; /* Let the height adjust according to width */
  }
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.single-message__info {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.725rem !important;
  font-style: normal;
  font-weight: 200;
  line-height: 1rem;
  text-align: right; // Aligns timestamp to the right
  margin-top: 0rem;
}
