div.chat-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  height: 100%;
  background: linear-gradient(180deg, #0e1823 24.44%, #030406 100%);

  &__messages {
    flex: 1;
    overflow: auto;

    div.messages {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      justify-content: flex-end;
    }
  }

  &__message-loader {
    border-radius: 0.75269rem;
    background: transparent;
    padding: 1rem 1.25rem;

    width: 6rem;

    display: flex;
    justify-content: center;
  }

  &__body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 60%; /* Default width */
    margin: 0 auto;
    flex: 1;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    &__body {
      width: 100%;
    }
  }
}
