.voice-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #0e1823;
    color: white;

    &__header {
        flex: 0 0 auto;
    }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }

    &__status {
        font-size: 1.2rem;
        margin-bottom: 2rem;
        text-align: center;
        min-height: 1.5em;
    }

    &__controls {
        display: flex;
        gap: 1rem;
        justify-content: center;
    }
}

.control-button {
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &.start {
        background-color: #4CAF50;
        color: white;

        &:hover {
            background-color: #45a049;
        }
    }

    &.cancel {
        background-color: #f44336;
        color: white;

        &:hover {
            background-color: #da190b;
        }

        &:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
        }
    }
}

.audio-player {
    display: none;
}
