/* Reset CSS */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  line-height: 1.5;
  font-family: Inter, Poppins, sans-serif; /* You can change this to your preferred font */
}

html {
  font-size: 16px !important;
}

@media (max-width: 400px) {
  html {
    font-size: 12px !important;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 16px !important;
  }
}

/* Larger screens */
@media (min-width: 1201px) {
  html {
    font-size: 16px; /* Default for larger screens */
  }
}

div#root {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
  display: block; /* Prevents bottom space under images */
}

ul,
ol {
  list-style: none; /* Removes default list styling */
}

a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits color from parent */
}

table {
  border-collapse: collapse; /* Collapses table borders */
  width: 100%; /* Ensures tables take full width */
}

/* Additional resets for better cross-browser consistency */
fieldset {
  border: none; /* Removes default border from fieldsets */
  padding: 0; /* Resets padding */
}

legend {
  display: none; /* Hides legend if not needed */
}

input {
  font-family: inherit; /* Ensures inputs inherit font */
  font-size: inherit;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s forwards;
}
