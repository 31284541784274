div.chat-input {
  &__container {
    padding: 1.04219rem 1.50544rem 0rem 1.04219rem;
    display: flex;
    // justify-content: space-between;
    box-shadow: 0px -11.117px 5.558px 0px rgba(0, 0, 0, 0.04);


    textarea {
      flex: 1;
      height: 2.8rem;
      padding: 0.625rem;
      border-radius: 0.5rem;
      border: 1px solid #b2bbc6;
      background-color: #142333;
      color: #899199;
      font-family: "Poppins", sans-serif;
      height: auto;

      &::placeholder {
        color: #909dad;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &:focus,
      &:focus-visible,
      &:focus-visible {
        outline: none;
        border-radius: 0.5rem;
        border: 1px solid white;
        background-color: #142333;
      }
    }

    &-icon {
      background: white;
      border-radius: 100%;
      height: 2.8rem;
      width: auto; /* Set height to auto */
      aspect-ratio: 1 / 1;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }

    &-delete {
      background: white;
      border-radius: 100%;
      height: 3rem;
      // width: auto; /* Set height to auto */
      aspect-ratio: 1 / 1;
      align-self: center;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }

    &-mic {
      background: white;
      border-radius: 100%;
      height: 3rem;
      // width: auto; /* Set height to auto */
      aspect-ratio: 1 / 1;
      align-self: center;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }
  }
  &__disclaimer {
    text-align: center;
    color: #979797;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    padding: 0.5rem 0rem;
  }
}
