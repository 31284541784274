div.header-container {
  display: inline-flex;
  background: transparent;
  width: 100%;

  justify-content: space-between;
  align-items: center;

  padding: 0.75rem 1.5281rem;

  border-bottom: 0.926px solid rgba(224, 224, 224, 0.3);

  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

  div.header-logo {
    font-size: 1.69844rem;
    font-style: normal;
    font-weight: 800;
    font-family: Poppins;
    line-height: normal;
    color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(91deg, #6ce4ff 1.46%, #3aa8c1 99.24%);
    background-clip: text;

    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  div.header-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.header-icon {
    margin-left: 3rem;
  }

  div.header-text {
    color: #fff;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
