.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
}

.message {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.sent {
  background-color: #d1f7c4;
  align-self: flex-end;
}

.received {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.input-area {
  display: flex;
  padding: 10px;
}

button {
  padding: 10px;
}

.app-container {
  width: 100%; /* Set the width */
  height: 100%; /* Let the height adjust automatically based on width for aspect ratio */

  overflow: hidden;

  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06);
}
