.loader {
  width: 0.75rem;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 1rem 0 rgba(255, 255, 255, 0.8),
      -1rem 0 rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 1); /* Full white */
  }
  33% {
    box-shadow: 1rem 0 rgba(255, 255, 255, 0.8),
      -1rem 0 rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.2); /* Lighter white */
  }
  66% {
    box-shadow: 1rem 0 rgba(255, 255, 255, 0.2),
      -1rem 0 rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.2); /* Lighter white */
  }
  100% {
    box-shadow: 1rem 0 rgba(255, 255, 255, 0.2),
      -1rem 0 rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 1); /* Full white */
  }
}

/* Adjust for screens smaller than 600px (mobile devices) */
@media only screen and (max-width: 600px) {
  .loader {
    width: 0.75rem; /* Smaller width for mobile */
  }

  @keyframes l5 {
    0% {
      box-shadow: 1rem 0 rgba(255, 255, 255, 0.8),
        -1rem 0 rgba(255, 255, 255, 0.2); /* Adjust box-shadow */
      background: rgba(255, 255, 255, 1);
    }
    33% {
      box-shadow: 1rem 0 rgba(255, 255, 255, 0.8),
        -1rem 0 rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.2);
    }
    66% {
      box-shadow: 1rem 0 rgba(255, 255, 255, 0.2),
        -1rem 0 rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.2);
    }
    100% {
      box-shadow: 1rem 0 rgba(255, 255, 255, 0.2),
        -1rem 0 rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 1);
    }
  }
}

/* Adjust for screens smaller than 400px (small mobile devices) */
@media only screen and (max-width: 400px) {
  .loader {
    width: 0.625rem; /* Even smaller for very small mobile devices */
  }

  @keyframes l5 {
    0% {
      box-shadow: 0.75rem 0 rgba(255, 255, 255, 0.8),
        -0.75rem 0 rgba(255, 255, 255, 0.2); /* Adjust box-shadow */
      background: rgba(255, 255, 255, 1);
    }
    33% {
      box-shadow: 0.75rem 0 rgba(255, 255, 255, 0.8),
        -0.75rem 0 rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.2);
    }
    66% {
      box-shadow: 0.75rem 0 rgba(255, 255, 255, 0.2),
        -0.75rem 0 rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.2);
    }
    100% {
      box-shadow: 0.75rem 0 rgba(255, 255, 255, 0.2),
        -0.75rem 0 rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 1);
    }
  }
}
