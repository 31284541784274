.audio-player {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    max-width: 100%;
}

.player-container {
    display: flex;
    align-items: center;
    flex-direction: o;
    max-width: 80%;
}

.play-btn {
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    border: none;
    color: #fff;
    font-size: 16px;
    margin-right: 10px;
    padding-left: 12px;
    cursor: pointer;
    width: 40px;           /* Set width to create a circular shape */
    height: 40px;          /* Set height to create a circular shape */
    border-radius: 50%;    /* Make the button round */
    display: flex;         /* Center the icon within the circle */
    align-items: center;   /* Vertically center the icon */
    justify-content: center; /* Horizontally center the icon */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}
.pause-btn {
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    border: none;
    color: #fff;
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    width: 40px;           /* Set width to create a circular shape */
    height: 40px;          /* Set height to create a circular shape */
    border-radius: 50%;    /* Make the button round */
    display: flex;         /* Center the icon within the circle */
    align-items: center;   /* Vertically center the icon */
    justify-content: center; /* Horizontally center the icon */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.play-pause-btn:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Darker on hover */
}

.time {
    font-size: 12px;
}

.duration {
    font-size: 12px;
    margin-left: 5px;
}

.visualizer-container {
    position: relative;
    width: 300px;
    height: 75px;
    margin: 0 0px;
}

.progress-overlay {
    background-color: rgba(0, 0, 0, 0.3); /* Progress overlay color */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    pointer-events: none;
}

.single-message__info {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.725rem !important;
    font-style: normal;
    font-weight: 200;
    line-height: 1rem;
    margin-top: 0rem;

    &.align-left {
        text-align: left;
        margin-left: 60px;
    }
    &.align-right {
        text-align: right;
    }
  }
  
div.single-message {
    &__container {
      display: flex;
      flex-direction: o;
      max-width: 80%;
      gap: 0.579rem;
  
      &.is-me {
        margin-left: auto;
        width: fit-content;
        margin-right: 1.25rem;
        align-items: flex-end;
      }
  
      &.is-not-me {
        margin-right: auto;
        width: fit-content;
      }
    }
}