div.login-form {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 1.689rem;

    div.loader {
      margin: 0 auto;
    }
  }

  &__header {
    color: #ddd;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    align-self: center;
  }

  &__info {
    color: #151a20;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    background: #fef6e1;

    display: flex;
    padding: 0.625rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1.69rem;

    &-otp {
      width: 100%;
      label {
        color: #cfd1d3;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      input {
        width: 100%;
        height: 3.5rem;
        padding: 0.625rem;
        border-radius: 0.5rem;
        border: 1px solid #1f3347;
        background: #142333;
        color: #899199;

        &::placeholder {
          color: #909dad;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          border-radius: 0.5rem;
          border: 1px solid #ccc;
          outline: none;
        }
      }

      div.error-message {
        color: #ff0000;
      }

      div.resend-otp {
        color: #6be2fd;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-phone {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      label {
        color: #cfd1d3;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &-input {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        position: relative;
        border: 1px solid #b2bbc6;
        border-radius: 0.5rem;
        overflow: hidden;

        &.hasValue {
          background-color: #142333;
        }

        &_number {
          width: 100%;
          height: 3.5rem;
          color: #909dad;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          align-items: center;
          padding: 0.625rem;

          &.hasValue {
            color: #151a20;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        &_edit {
          color: #6be2fd;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          margin-right: 0.625rem;
        }
      }

      &-input-picker {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        position: relative;
        width: 100%;

        div.react-tel-input {
          input {
            width: 100%;
            height: 3.5rem;
            border: 1px solid #b2bbc6;
            border-radius: 0.5rem;
          }

          div.flag-dropdown {
            border: 1px solid #b2bbc6;
            border-radius: 0.5rem 0 0 0.5rem;
          }
        }

        input {
          border: 1px solid #1f3347;
          background: #142333;
          width: 100%;
          height: 3.5rem;

          &::placeholder {
            color: #909dad;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &_edit {
          color: #e46408;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          position: absolute;
          right: 2%;

          margin-right: 0.625rem;
        }
      }
    }
  }

  &__actions {
    button.btn-submit,
    button.btn-otp {
      display: flex;
      height: 3.5rem;
      padding: 0.625rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
      border-radius: 0.25rem;
      background: #fff;
      border: none;

      color: #0e1823;

      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      width: 100%;
    }
  }
}

.login-form__body-otp {
  .input-group {
    display: flex;
    align-items: center;

    .input-field {
      flex: 1;
      padding: 0.5rem;

      font-size: 1rem;

      border: 1px solid #1f3347;
      background: #142333;

      color: #899199;

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: none;
        border: 1px solid #ccc;
      }
    }
  }
}
