.country-code-selector {
  position: relative;
  height: 3.5rem;
  margin-right: 0.5rem;

  .selected-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #1f3347;
    cursor: pointer;
    background-color: #142333;
    height: 100%;

    color: #cfd1d3;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .arrow {
      margin-left: 0.5rem;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      &.down {
        border-top: 6px solid #888;
      }

      &.up {
        border-bottom: 6px solid #888;
      }
    }
  }

  .options-list {
    position: absolute;
    top: calc(100% + 0.2rem);
    left: 0;
    width: 100%;
    max-height: 200px; // Fixed height
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000; // Ensure it appears above other elements

    .option {
      padding: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
