/* styles.scss */

div.phone-auth {
  &__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow: hidden;
    background: linear-gradient(180deg, #0e1823 24.44%, #030406 100%);
  }

  &__body {
    flex: 1;
    overflow: auto;
    background: linear-gradient(180deg, #0e1823 24.44%, #030406 100%);

    &--login-form {
      width: 80%; /* Default width for small screens (mobile) */
      margin: 0 auto;
      transform: translateY(20%);
      top: 15%;
    }

    /* Adjust for screens larger than 600px (tablets and desktop) */
    @media only screen and (min-width: 600px) {
      &--login-form {
        width: 50%; /* 50% width for tablets and desktop */
      }
    }
  }
}
